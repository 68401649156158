@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Open Sans", sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Poppins", sans-serif;
  }
}
